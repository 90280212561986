<template>

  <div>
    <b-card title="Queue Settings">
      <b-button-toolbar justify>
        <div />
        <div>
          <b-button
            v-if="activeUserInfo.userRole === 'admin' || activeUserInfo.userRole === 'company admin'"
            v-b-tooltip.hover="'Update'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Update()"
          >
            <font-awesome-icon
              :icon="['far', 'save']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <div class="divider my-2">
        <div class="divider-text">
          Production Sender Queue
        </div>
      </div>
      <b-row>
        <b-col md="2">
          <label><i><b>Enable:</b></i></label>
          <b-form-checkbox v-model="ProductionSend.IsEnabled" />
        </b-col>
        <b-col md="2">
          <label><i><b>API:</b></i></label>
          <b-form-checkbox v-model="ProductionSend.IsAPI" />
        </b-col>
        <b-col md="6">
          <label><i><b>Host:</b></i></label>
          <b-form-input
            id="ProductionSendHost"
            v-model="ProductionSend.Host"
            placeholder="HOST"
          />
        </b-col>
        <b-col md="2">
          <label><i><b>Port:</b></i></label>
          <b-form-input
            id="ProductionSendPort"
            v-model="ProductionSend.Port"
            type="number"
            number
            placeholder="Port"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <label><i><b>Queue Manager:</b></i></label>
          <b-form-input
            id="ProductionSendQM"
            v-model="ProductionSend.QM"
            placeholder="Queue Manager"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Queue:</b></i></label>
          <b-form-input
            id="ProductionSendQueue"
            v-model="ProductionSend.Queue"
            placeholder="Queue"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Channel Name:</b></i></label>
          <b-form-input
            id="ProductionSendChannel"
            v-model="ProductionSend.Channel"
            placeholder="Channel"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <label><i><b>Username:</b></i></label>
          <b-form-input
            id="ProductionSendUsername"
            v-model="ProductionSend.Username"
            placeholder="Username"
          />
        </b-col>
        <b-col md="6">
          <label><i><b>Password:</b></i></label>
          <b-form-input
            id="ProductionSendPassword"
            v-model="ProductionSend.Password"
            type="password"
            placeholder="Password"
          />
        </b-col>
      </b-row>

      <div class="divider my-2">
        <div class="divider-text">
          Production Receiver Queue
        </div>
      </div>
      <b-row>
        <b-col md="2">
          <label><i><b>Enable:</b></i></label>
          <b-form-checkbox v-model="ProductionReceive.IsEnabled" />
        </b-col>
        <b-col md="2">
          <label><i><b>API:</b></i></label>
          <b-form-checkbox v-model="ProductionReceive.IsAPI" />
        </b-col>
        <b-col md="6">
          <label><i><b>Host:</b></i></label>
          <b-form-input
            id="ProductionReceiveHost"
            v-model="ProductionReceive.Host"
            placeholder="HOST"
          />
        </b-col>
        <b-col md="2">
          <label><i><b>Port:</b></i></label>
          <b-form-input
            id="ProductionReceivePort"
            v-model="ProductionReceive.Port"
            type="number"
            number
            placeholder="Port"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <label><i><b>Queue Manager:</b></i></label>
          <b-form-input
            id="ProductionReceiveQM"
            v-model="ProductionReceive.QM"
            placeholder="Queue Manager"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Queue:</b></i></label>
          <b-form-input
            id="ProductionReceiveQueue"
            v-model="ProductionReceive.Queue"
            placeholder="Queue"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Channel Name:</b></i></label>
          <b-form-input
            id="ProductionReceiveChannel"
            v-model="ProductionReceive.Channel"
            placeholder="Channel"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <label><i><b>Username:</b></i></label>
          <b-form-input
            id="ProductionReceiveUsername"
            v-model="ProductionReceive.Username"
            placeholder="Username"
          />
        </b-col>
        <b-col md="6">
          <label><i><b>Password:</b></i></label>
          <b-form-input
            id="ProductionReceivePassword"
            v-model="ProductionReceive.Password"
            type="password"
            placeholder="Password"
          />
        </b-col>
      </b-row>

      <div class="divider my-2">
        <div class="divider-text">
          Certification Sender Queue
        </div>
      </div>
      <b-row>
        <b-col md="2">
          <label><i><b>Enable:</b></i></label>
          <b-form-checkbox v-model="CertificationSend.IsEnabled" />
        </b-col>
        <b-col md="2">
          <label><i><b>API:</b></i></label>
          <b-form-checkbox v-model="CertificationSend.IsAPI" />
        </b-col>
        <b-col md="6">
          <label><i><b>Host:</b></i></label>
          <b-form-input
            id="CertificationSendHost"
            v-model="CertificationSend.Host"
            placeholder="HOST"
          />
        </b-col>
        <b-col md="2">
          <label><i><b>Port:</b></i></label>
          <b-form-input
            id="CertificationSendPort"
            v-model="CertificationSend.Port"
            type="number"
            number
            placeholder="Port"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <label><i><b>Queue Manager:</b></i></label>
          <b-form-input
            id="CertificationSendQM"
            v-model="CertificationSend.QM"
            placeholder="Queue Manager"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Queue:</b></i></label>
          <b-form-input
            id="CertificationSendQueue"
            v-model="CertificationSend.Queue"
            placeholder="Queue"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Channel Name:</b></i></label>
          <b-form-input
            id="CertificationSendChannel"
            v-model="CertificationSend.Channel"
            placeholder="Channel"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <label><i><b>Username:</b></i></label>
          <b-form-input
            id="CertificationSendUsername"
            v-model="CertificationSend.Username"
            placeholder="Username"
          />
        </b-col>
        <b-col md="6">
          <label><i><b>Password:</b></i></label>
          <b-form-input
            id="CertificationSendPassword"
            v-model="CertificationSend.Password"
            type="password"
            placeholder="Password"
          />
        </b-col>
      </b-row>

      <div class="divider my-2">
        <div class="divider-text">
          Certification Receiver Queue
        </div>
      </div>
      <b-row>
        <b-col md="2">
          <label><i><b>Enable:</b></i></label>
          <b-form-checkbox v-model="CertificationReceive.IsEnabled" />
        </b-col>
        <b-col md="2">
          <label><i><b>API:</b></i></label>
          <b-form-checkbox v-model="CertificationReceive.IsAPI" />
        </b-col>
        <b-col md="6">
          <label><i><b>Host:</b></i></label>
          <b-form-input
            id="CertificationReceiveHost"
            v-model="CertificationReceive.Host"
            placeholder="HOST"
          />
        </b-col>
        <b-col md="2">
          <label><i><b>Port:</b></i></label>
          <b-form-input
            id="CertificationReceivePort"
            v-model="CertificationReceive.Port"
            type="number"
            number
            placeholder="Port"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <label><i><b>Queue Manager:</b></i></label>
          <b-form-input
            id="CertificationReceiveQM"
            v-model="CertificationReceive.QM"
            placeholder="Queue Manager"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Queue:</b></i></label>
          <b-form-input
            id="CertificationReceiveQueue"
            v-model="CertificationReceive.Queue"
            placeholder="Queue"
          />
        </b-col>
        <b-col md="4">
          <label><i><b>Channel Name:</b></i></label>
          <b-form-input
            id="CertificationReceiveChannel"
            v-model="CertificationReceive.Channel"
            placeholder="Channel"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <label><i><b>Username:</b></i></label>
          <b-form-input
            id="CertificationReceiveUsername"
            v-model="CertificationReceive.Username"
            placeholder="Username"
          />
        </b-col>
        <b-col md="6">
          <label><i><b>Password:</b></i></label>
          <b-form-input
            id="CertificationReceivePassword"
            v-model="CertificationReceive.Password"
            type="password"
            placeholder="Password"
          />
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      ProductionSend: {
        Id: '',
        IsEnabled: false,
        IsAPI: false,
        Host: '',
        Port: '',
        QM: '',
        Queue: '',
        Channel: '',
        Username: '',
        Password: '',
      },
      ProductionReceive: {
        Id: '',
        IsEnabled: false,
        IsAPI: false,
        Host: '',
        Port: '',
        QM: '',
        Queue: '',
        Channel: '',
        Username: '',
        Password: '',
      },
      CertificationSend: {
        Id: '',
        IsEnabled: false,
        IsAPI: false,
        Host: '',
        Port: '',
        QM: '',
        Queue: '',
        Channel: '',
        Username: '',
        Password: '',
      },
      CertificationReceive: {
        Id: '',
        IsEnabled: false,
        IsAPI: false,
        Host: '',
        Port: '',
        QM: '',
        Queue: '',
        Channel: '',
        Username: '',
        Password: '',
      },

    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.GetQueueSetting()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    GetQueueSetting() {
      return axios.get('/abi/settings/queue/all')
        .then(resp => {
          const response = resp.data
          response.forEach(e => {
            if (e.direction === '0' && e.isProduction) { // 0 = From, 1 = To
              this.ProductionReceive.Id = e.id
              this.ProductionReceive.IsEnabled = e.isEnabled
              this.ProductionReceive.IsAPI = e.isAPI
              this.ProductionReceive.Host = e.host
              this.ProductionReceive.Port = e.port
              this.ProductionReceive.QM = e.qm
              this.ProductionReceive.Queue = e.queue
              this.ProductionReceive.Channel = e.channelName
              this.ProductionReceive.Username = e.username
              this.ProductionReceive.Password = e.password
            } else if (e.direction === '0' && !e.isProduction) {
              this.CertificationReceive.Id = e.id
              this.CertificationReceive.IsEnabled = e.isEnabled
              this.CertificationReceive.IsAPI = e.isAPI
              this.CertificationReceive.Host = e.host
              this.CertificationReceive.Port = e.port
              this.CertificationReceive.QM = e.qm
              this.CertificationReceive.Queue = e.queue
              this.CertificationReceive.Channel = e.channelName
              this.CertificationReceive.Username = e.username
              this.CertificationReceive.Password = e.password
            } else if (e.direction === '1' && e.isProduction) {
              this.ProductionSend.Id = e.id
              this.ProductionSend.IsEnabled = e.isEnabled
              this.ProductionSend.IsAPI = e.isAPI
              this.ProductionSend.Host = e.host
              this.ProductionSend.Port = e.port
              this.ProductionSend.QM = e.qm
              this.ProductionSend.Queue = e.queue
              this.ProductionSend.Channel = e.channelName
              this.ProductionSend.Username = e.username
              this.ProductionSend.Password = e.password
            } else if (e.direction === '1' && !e.isProduction) {
              this.CertificationSend.Id = e.id
              this.CertificationSend.IsEnabled = e.isEnabled
              this.CertificationSend.IsAPI = e.isAPI
              this.CertificationSend.Host = e.host
              this.CertificationSend.Port = e.port
              this.CertificationSend.QM = e.qm
              this.CertificationSend.Queue = e.queue
              this.CertificationSend.Channel = e.channelName
              this.CertificationSend.Username = e.username
              this.CertificationSend.Password = e.password
            }
          })
        })
    },
    Update() {
      this.LoadingStart()
      const MQInstanceModel = [
        {
          Id: this.ProductionReceive.Id,
          IsEnabled: this.ProductionReceive.IsEnabled === null ? false : this.ProductionReceive.IsEnabled,
          IsAPI: this.ProductionReceive.IsAPI === null ? false : this.ProductionReceive.IsAPI,
          Host: this.ProductionReceive.Host,
          Port: this.ProductionReceive.Port,
          QM: this.ProductionReceive.QM,
          Queue: this.ProductionReceive.Queue,
          ChannelName: this.ProductionReceive.Channel,
          Username: this.ProductionReceive.Username,
          Password: this.ProductionReceive.Password,
        },
        {
          Id: this.ProductionSend.Id,
          IsEnabled: this.ProductionSend.IsEnabled === null ? false : this.ProductionSend.IsEnabled,
          IsAPI: this.ProductionSend.IsAPI === null ? false : this.ProductionSend.IsAPI,
          Host: this.ProductionSend.Host,
          Port: this.ProductionSend.Port,
          QM: this.ProductionSend.QM,
          Queue: this.ProductionSend.Queue,
          ChannelName: this.ProductionSend.Channel,
          Username: this.ProductionSend.Username,
          Password: this.ProductionSend.Password,
        },
        {
          Id: this.CertificationReceive.Id,
          IsEnabled: this.CertificationReceive.IsEnabled === null ? false : this.CertificationReceive.IsEnabled,
          IsAPI: this.CertificationReceive.IsAPI === null ? false : this.CertificationReceive.IsAPI,
          Host: this.CertificationReceive.Host,
          Port: this.CertificationReceive.Port,
          QM: this.CertificationReceive.QM,
          Queue: this.CertificationReceive.Queue,
          ChannelName: this.CertificationReceive.Channel,
          Username: this.CertificationReceive.Username,
          Password: this.CertificationReceive.Password,
        },
        {
          Id: this.CertificationSend.Id,
          IsEnabled: this.CertificationSend.IsEnabled === null ? false : this.CertificationSend.IsEnabled,
          IsAPI: this.CertificationSend.IsAPI === null ? false : this.CertificationSend.IsAPI,
          Host: this.CertificationSend.Host,
          Port: this.CertificationSend.Port,
          QM: this.CertificationSend.QM,
          Queue: this.CertificationSend.Queue,
          ChannelName: this.CertificationSend.Channel,
          Username: this.CertificationSend.Username,
          Password: this.CertificationSend.Password,
        },
      ]
      axios.put('/abi/settings/queue/update', MQInstanceModel).then(() => {
        this.LoadingEnd()
        this.DisplaySuccess('Queue Setting data has been saved.')
      })
    },
  },
}
</script>
